import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");
const header_image_mobile = require("../../../assets/img/blogs/cio_persp_blog_header_mob.png");
const header_image = require("../../../assets/img/blogs/cio_persp_blog_header.png");
const section_1 = require("../../../assets/img/blogs/cio_persp_blog_img_1.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });

  const backgroundImage = isSmall ? header_image_mobile : header_image;
  return (
    <>
      <TitleAndMetaTags
        title="CIO Perspective: Lessons learned from Generative AI Implementation"
        description="Generative AI implementation needs careful observation before, during, and after to ensure project success. Learn significant lessons from GenAI implementation. "
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section
            className={`w-100 float-left blog_top_header_upt cio_persp_blog_header_upt ${
              isSmall ? "align-items-center" : null
            }`}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile
                    ? "text-align-center mt-0"
                    : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-page-header-home-blog-bigger color-black line-height-18">
                    CIO Perspective: Lessons learned from Generative AI
                    Implementation
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog-employee color-black">
                    CIO Perspective:
                    <br /> Lessons learned from
                    <br /> Generative AI
                    <br /> Implementation
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. GenAI-based bot for IT support
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. How was the IT support bot built?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. The issue of GenAI-based bot implementation for IT support
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Lessons learned from the implementation
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise">
                  Generative AI
                </a>{" "}
                is moving so fast that industry leaders fear falling behind if
                they delay acting now.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Like other technology revolutions—the Internet, smartphones,
                cloud technology, and IoT—that initially created much buzz,
                Generative AI unleashes the same frenzy.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is nascent and susceptible to risks, yet leaders want to
                leverage it for greater business outcomes and benefits.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                With the growing expectations around generative AI use cases,
                leaders want to deploy GenAI-powered applications or solutions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Given a project’s successful needs, leaders can follow the CIO
                or CTO's{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-chatbot-guide">
                  guide to implementing Generative AI.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, implementing a GenAI project is not the end of the
                game. A leader must learn too many critical things from
                generative AI implementation.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Lessons learned from GenAI implementation can enhance your
                understanding of what works best for each iteration and what to
                do if a system hallucinates or its performance deteriorates.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Simultaneously, what you can do better is align with the change
                management and bring stakeholders on the same page.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Implementation checks are essential to identify and prevent
                crucial challenges from impacting a project.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s check what can happen after the implementation of GenAI
                from a real-world experience, as{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ
                </a>{" "}
                has demonstrated.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We also provide useful insights to avoid such challenges as you
                aim for a GenAI solution that performs continuously well.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                GenAI-based bot for IT support
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                By combining the{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  power of generative AI,
                </a>{" "}
                IT leaders can gain significant benefits for IT support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With the IT team growing, it receives a massive volume of daily
                IT requests due to the influx of digital tools, such as
                productivity apps, collaboration tools, visual workspaces, etc.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A typical self-service bot can include automation for repetitive
                tasks but would be limited in extended automation capabilities.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    It can work with a handful of use cases, requiring human
                    intervention for most queries.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Knowledge articles reflect outdated information or are not
                    at par with the present challenges or needs.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    IT leaders must be alert about delegating tasks to someone
                    to update knowledge articles.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    The lack of proper skills can delay knowledge development.
                  </span>
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI, with its LLM capabilities, can apply RAGs to its
                massive datasets and retrieve answers similar to user queries.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Whether the employee support queries grow, Generative AI helps
                improve knowledge discovery and resolve issues autonomously
                using LLMs.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Generative AI continuously learns from interactions and
                    surfaces answers even if the database encompasses no match
                    for the query.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    With the ability to build predictive analytics, GenAI can
                    help reduce escalation calls by providing better
                    recommendations for issue resolution.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Generative AI reduces the need to create knowledge
                    occasionally with its capability to generate NLP-based
                    content.
                  </span>
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI can help industries eliminate the challenges for
                service desks to facilitate autonomous problem resolution for
                repetitive tasks, escalation of critical issues for human
                agents, and improvement of employee experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ drives efficiency for service desk managers, helping
                them handle employee IT issues and requests by applying app
                workflow automation to generative AI.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ helps one of the leading IT companies reduce the
                repetitive service desk challenges using generative AI-based
                workflows.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI-based workflows help automate multiple use cases
                for the following requests,
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>Reset password</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>Install software</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>Install printer</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>Unlock account</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>Deactivate MFA</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>Create user phone number, etc</span>
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18">
                The hands-on experience with the GenAI implementation gives
                exposure to some critical insights.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s visualize these insights from the perspective of Workativ
                with our tryst with a leading IT service provider.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How was the IT support bot built?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                With Workativ, our customers do not have to do any hard work.
                Users can use a no-code conversational AI platform to build
                their preferred workflows.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We built a GenAI bot for our customer to solve their existing IT
                support issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ gathered business use case data from 50+ apps and
                trained the GenAI platform through knowledge article uploads.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                These IT support cases included information related to asset
                management processes, IT service uptime, user interactions,
                action data by agents, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                By enabling generative AI in the chatbot builder, the bot was
                tested and deployed to use LLM and surface answers using
                prompts.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                The issue of GenAI-based bot implementation for IT support
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                The intent behind the IT support bot for our client was to
                leverage generative AI properties, automate tasks, and reduce
                workloads on the service desk.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, IT support reveals certain limitations on Generative AI
                capabilities.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Answers were inappropriate
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                The GenAI bot was built to deliver ChatGPT-like responses.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                However, it was not meant to surface generalized answers, and
                fine-tuning was not intended as it could only provide custom
                answers for one or two use cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The Workativ chatbot builder platform allows LLMs to train by
                uploading knowledge articles.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Later, it was observed that the self-service platform
                demonstrated critical challenges in surfacing wrong answers
                for&nbsp;
                <span className="font-section-normal-text-testimonials-medium">
                  service desk users.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example, if a question were asked about ways to enable
                multi-factor authentication, it would suggest recommendations
                for apps that were no longer intended or part of enterprise
                workflows. This was one of the scenarios. There were many odd
                situations for users who continued to receive wrong or
                inappropriate answers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It was found that the workflows pulled up information from
                outdated knowledge articles. These knowledge resources failed to
                comply with the current needs of business use cases.
              </p>

              <h3 className="font-section-sub-header-small-home">
                Model hallucination
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                There were ethical considerations as well. The GenAI workflows
                failed to comply with the business-specific scenarios. Due to
                the knowledge bases reflecting old and outdated knowledge, the
                IT support bot provided incorrect guidance to the users.
                Besides, some questions without a match in knowledge bases could
                have answers designed with guesswork, leading to model
                hallucinations. A typical example of model hallucinations could
                be like this—
              </p>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="GenAI hallucinations example "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This type of suggestion did not meet the expectations of
                business outcomes. Also, a random suggestion could trigger
                confusion and the need for immediate attention from respected
                stakeholders.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Misalignment of document formats
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                One of the behind-the-scenes reasons the GenAI interface
                delivered inappropriate answers or gave no answers at all was
                that document formats for knowledge articles incurred
                compatibility issues with the system.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Incompatibility of document formats caused misalignment with the
                system and hindered the system from storing necessary
                information.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Lessons learned from the implementation
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                We gathered a couple of significant takeaways from implementing
                an IT support bot with generative AI capabilities for our
                customers.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Human error can damage great technological innovation and
                overshadow the potential benefits of Generative AI.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Here’s how you can measure the value of each model option—a
                fine-tuned model, custom model, and no-code AI copilot.
              </p>

              <h3 className="font-section-sub-header-small">Productivity</h3>

              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials pl-4">
                <li class="font-section-normal-text-testimonials line-height-18">
                  <span class="font-section-normal-text-testimonials-medium color-black">
                    1. Human-in-the-loop:{" "}
                  </span>
                  Human oversight is significant in ensuring that data used for
                  model training is always current. When the key objective is to
                  reflect domain-specific knowledge sharing to help solve a
                  problem, data must encompass real-world experiences from IT
                  support about a specific business. Also, IT support can
                  interact with industry-wide scenarios for common issues across
                  the industry. Depending on this, users must also provide a
                  world of knowledge via integration with correctly configured
                  third-party LLM solutions.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                Based on the observation, our customer removed outdated
                knowledge articles from the platform and uploaded the latest
                articles with use cases relevant to the present scenarios and
                business needs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                Also, it was recommended that any knowledge implementation
                should be done with AI expertise and domain knowledge.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials pl-4">
                <li class="font-section-normal-text-testimonials line-height-18">
                  <span class="font-section-normal-text-testimonials-medium color-black">
                    2. Elimination of hallucination:{" "}
                  </span>
                  The underlying fact is that the more accurate the data, the
                  more precise the answers are. Human oversight, while data is
                  uploaded to the model or during the preparation of the
                  knowledge articles, can be a good way to prevent the
                  GenAI-based IT support bot from surfacing hallucinated
                  answers.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                Our customer ensured knowledge articles were correct and
                relevant to their domain-specific cases.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials pl-4">
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span class="font-section-normal-text-testimonials-medium color-black">
                    3. Use of properly formatted documents:{" "}
                  </span>
                  If the format of documents matters for a Generative AI
                  platform that could help data ingestion, users must take care
                  of this side. They must upload knowledge articles in preferred
                  formats, such as PDFs, CSV files, XLS format, etc, to help the
                  system extract knowledge correctly and surface appropriate
                  answers.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                Our customer applied documents in the right format to support
                system compatibility and also ensured the GenAI bot could
                deliver correct responses and help resolve IT issues to the
                maximum length.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials pl-4">
                <li class="font-section-normal-text-testimonials line-height-18">
                  <span class="font-section-normal-text-testimonials-medium color-black">
                    4. Data governance or AI regulations:{" "}
                  </span>
                  Generative AI is known for data violation nuances. We already
                  have many instances of data violations and security risks. The
                  AI Act and other GDPR put into effect stricter regulations to
                  safeguard against data use and facilitate transparency and
                  security.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                To help clients of all sizes with data protection for security
                and privacy and compliance with regulations, we configured
                knowledge bases to prevent data violation while surfacing
                answers. Besides, we implemented a configuration that could
                prevent our model from storing users’ inputs and using them to
                train the workflows.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials pl-4">
                <li class="font-section-normal-text-testimonials line-height-18">
                  <span class="font-section-normal-text-testimonials-medium color-black">
                    5.{" "}
                  </span>
                  Lastly, from Workativ’s perspective, workflows can perform
                  inappropriately due to outdated knowledge. Continuous
                  monitoring of the system is thus significant in helping users
                  leverage the latest information and correctly use generative
                  AI to resolve IT support issues. This requires leaders to
                  retain the right talent with domain expertise. There’s no
                  doubt that no-code platforms can be leveraged to implement
                  workflows without writing a single line of code. But, with
                  thorough supervision from AI experts and domain knowledge, you
                  maintain your systems and meet your users’ needs.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                Generative AI implementation can be overwhelming for companies
                due to the lack of proper assistance from experts with domain
                knowledge. Deploying a Generative AI system is just a piece of a
                puzzle. It requires end-to-end monitoring of system performance
                and user experience. Workativ provides a no-code platform for
                Generative AI implementation. Our post-implementation support is
                superior to helping clients onboard and efficiently manage all
                processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                Are you interested in successfully implementing your Generative
                AI workflows?{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  Book a demo today
                </a>{" "}
                with Workativ.
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. GenAI-based bot for IT support
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. How was the IT support bot built?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. The issue of GenAI-based bot implementation for IT
                    support
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. Lessons learned from the implementation
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise">
                  Generative AI
                </a>{" "}
                is moving so fast that industry leaders fear falling behind if
                they delay acting now.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Like other technology revolutions—the Internet, smartphones,
                cloud technology, and IoT—that initially created much buzz,
                Generative AI unleashes the same frenzy.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is nascent and susceptible to risks, yet leaders want to
                leverage it for greater business outcomes and benefits.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                With the growing expectations around generative AI use cases,
                leaders want to deploy GenAI-powered applications or solutions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Given a project’s successful needs, leaders can follow the CIO
                or CTO's{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-chatbot-guide">
                  guide to implementing Generative AI.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, implementing a GenAI project is not the end of the
                game. A leader must learn too many critical things from
                generative AI implementation.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Lessons learned from GenAI implementation can enhance your
                understanding of what works best for each iteration and what to
                do if a system hallucinates or its performance deteriorates.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Simultaneously, what you can do better is align with the change
                management and bring stakeholders on the same page.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Implementation checks are essential to identify and prevent
                crucial challenges from impacting a project.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s check what can happen after the implementation of GenAI
                from a real-world experience, as{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ
                </a>{" "}
                has demonstrated.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We also provide useful insights to avoid such challenges as you
                aim for a GenAI solution that performs continuously well.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                GenAI-based bot for IT support
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                By combining the{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  power of generative AI,
                </a>{" "}
                IT leaders can gain significant benefits for IT support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With the IT team growing, it receives a massive volume of daily
                IT requests due to the influx of digital tools, such as
                productivity apps, collaboration tools, visual workspaces, etc.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A typical self-service bot can include automation for repetitive
                tasks but would be limited in extended automation capabilities.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials pl-3">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    It can work with a handful of use cases, requiring human
                    intervention for most queries.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Knowledge articles reflect outdated information or are not
                    at par with the present challenges or needs.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    IT leaders must be alert about delegating tasks to someone
                    to update knowledge articles.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    The lack of proper skills can delay knowledge development.
                  </span>
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI, with its LLM capabilities, can apply RAGs to its
                massive datasets and retrieve answers similar to user queries.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Whether the employee support queries grow, Generative AI helps
                improve knowledge discovery and resolve issues autonomously
                using LLMs.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials pl-3">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Generative AI continuously learns from interactions and
                    surfaces answers even if the database encompasses no match
                    for the query.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    With the ability to build predictive analytics, GenAI can
                    help reduce escalation calls by providing better
                    recommendations for issue resolution.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Generative AI reduces the need to create knowledge
                    occasionally with its capability to generate NLP-based
                    content.
                  </span>
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI can help industries eliminate the challenges for
                service desks to facilitate autonomous problem resolution for
                repetitive tasks, escalation of critical issues for human
                agents, and improvement of employee experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ drives efficiency for service desk managers, helping
                them handle employee IT issues and requests by applying app
                workflow automation to generative AI.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ helps one of the leading IT companies reduce the
                repetitive service desk challenges using generative AI-based
                workflows.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI-based workflows help automate multiple use cases
                for the following requests,
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials pl-3">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>Reset password</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>Install software</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>Install printer</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>Unlock account</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>Deactivate MFA</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>Create user phone number, etc</span>
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18">
                The hands-on experience with the GenAI implementation gives
                exposure to some critical insights.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s visualize these insights from the perspective of Workativ
                with our tryst with a leading IT service provider.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How was the IT support bot built?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                With Workativ, our customers do not have to do any hard work.
                Users can use a no-code conversational AI platform to build
                their preferred workflows.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We built a GenAI bot for our customer to solve their existing IT
                support issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ gathered business use case data from 50+ apps and
                trained the GenAI platform through knowledge article uploads.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                These IT support cases included information related to asset
                management processes, IT service uptime, user interactions,
                action data by agents, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                By enabling generative AI in the chatbot builder, the bot was
                tested and deployed to use LLM and surface answers using
                prompts.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                The issue of GenAI-based bot implementation for IT support
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                The intent behind the IT support bot for our client was to
                leverage generative AI properties, automate tasks, and reduce
                workloads on the service desk.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, IT support reveals certain limitations on Generative AI
                capabilities.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Answers were inappropriate
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                The GenAI bot was built to deliver ChatGPT-like responses.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                However, it was not meant to surface generalized answers, and
                fine-tuning was not intended as it could only provide custom
                answers for one or two use cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The Workativ chatbot builder platform allows LLMs to train by
                uploading knowledge articles.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Later, it was observed that the self-service platform
                demonstrated critical challenges in surfacing wrong answers
                for&nbsp;
                <span className="font-section-normal-text-testimonials-medium">
                  service desk users.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example, if a question were asked about ways to enable
                multi-factor authentication, it would suggest recommendations
                for apps that were no longer intended or part of enterprise
                workflows. This was one of the scenarios. There were many odd
                situations for users who continued to receive wrong or
                inappropriate answers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It was found that the workflows pulled up information from
                outdated knowledge articles. These knowledge resources failed to
                comply with the current needs of business use cases.
              </p>

              <h3 className="font-section-sub-header-small-home">
                Model hallucination
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                There were ethical considerations as well. The GenAI workflows
                failed to comply with the business-specific scenarios. Due to
                the knowledge bases reflecting old and outdated knowledge, the
                IT support bot provided incorrect guidance to the users.
                Besides, some questions without a match in knowledge bases could
                have answers designed with guesswork, leading to model
                hallucinations. A typical example of model hallucinations could
                be like this—
              </p>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="GenAI hallucinations example "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This type of suggestion did not meet the expectations of
                business outcomes. Also, a random suggestion could trigger
                confusion and the need for immediate attention from respected
                stakeholders.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Misalignment of document formats
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                One of the behind-the-scenes reasons the GenAI interface
                delivered inappropriate answers or gave no answers at all was
                that document formats for knowledge articles incurred
                compatibility issues with the system.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Incompatibility of document formats caused misalignment with the
                system and hindered the system from storing necessary
                information.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Lessons learned from the implementation
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                We gathered a couple of significant takeaways from implementing
                an IT support bot with generative AI capabilities for our
                customers.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Human error can damage great technological innovation and
                overshadow the potential benefits of Generative AI.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Here’s how you can measure the value of each model option—a
                fine-tuned model, custom model, and no-code AI copilot.
              </p>

              <h3 className="font-section-sub-header-small">Productivity</h3>

              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials pl-4">
                <li class="font-section-normal-text-testimonials line-height-18">
                  <span class="font-section-normal-text-testimonials-medium color-black">
                    1. Human-in-the-loop:{" "}
                  </span>
                  Human oversight is significant in ensuring that data used for
                  model training is always current. When the key objective is to
                  reflect domain-specific knowledge sharing to help solve a
                  problem, data must encompass real-world experiences from IT
                  support about a specific business. Also, IT support can
                  interact with industry-wide scenarios for common issues across
                  the industry. Depending on this, users must also provide a
                  world of knowledge via integration with correctly configured
                  third-party LLM solutions.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                Based on the observation, our customer removed outdated
                knowledge articles from the platform and uploaded the latest
                articles with use cases relevant to the present scenarios and
                business needs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                Also, it was recommended that any knowledge implementation
                should be done with AI expertise and domain knowledge.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials pl-4">
                <li class="font-section-normal-text-testimonials line-height-18">
                  <span class="font-section-normal-text-testimonials-medium color-black">
                    2. Elimination of hallucination:{" "}
                  </span>
                  The underlying fact is that the more accurate the data, the
                  more precise the answers are. Human oversight, while data is
                  uploaded to the model or during the preparation of the
                  knowledge articles, can be a good way to prevent the
                  GenAI-based IT support bot from surfacing hallucinated
                  answers.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                Our customer ensured knowledge articles were correct and
                relevant to their domain-specific cases.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials pl-4">
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span class="font-section-normal-text-testimonials-medium color-black">
                    3. Use of properly formatted documents:{" "}
                  </span>
                  If the format of documents matters for a Generative AI
                  platform that could help data ingestion, users must take care
                  of this side. They must upload knowledge articles in preferred
                  formats, such as PDFs, CSV files, XLS format, etc, to help the
                  system extract knowledge correctly and surface appropriate
                  answers.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                Our customer applied documents in the right format to support
                system compatibility and also ensured the GenAI bot could
                deliver correct responses and help resolve IT issues to the
                maximum length.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials pl-4">
                <li class="font-section-normal-text-testimonials line-height-18">
                  <span class="font-section-normal-text-testimonials-medium color-black">
                    4. Data governance or AI regulations:{" "}
                  </span>
                  Generative AI is known for data violation nuances. We already
                  have many instances of data violations and security risks. The
                  AI Act and other GDPR put into effect stricter regulations to
                  safeguard against data use and facilitate transparency and
                  security.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                To help clients of all sizes with data protection for security
                and privacy and compliance with regulations, we configured
                knowledge bases to prevent data violation while surfacing
                answers. Besides, we implemented a configuration that could
                prevent our model from storing users’ inputs and using them to
                train the workflows.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials pl-4">
                <li class="font-section-normal-text-testimonials line-height-18">
                  <span class="font-section-normal-text-testimonials-medium color-black">
                    5.{" "}
                  </span>
                  Lastly, from Workativ’s perspective, workflows can perform
                  inappropriately due to outdated knowledge. Continuous
                  monitoring of the system is thus significant in helping users
                  leverage the latest information and correctly use generative
                  AI to resolve IT support issues. This requires leaders to
                  retain the right talent with domain expertise. There’s no
                  doubt that no-code platforms can be leveraged to implement
                  workflows without writing a single line of code. But, with
                  thorough supervision from AI experts and domain knowledge, you
                  maintain your systems and meet your users’ needs.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                Generative AI implementation can be overwhelming for companies
                due to the lack of proper assistance from experts with domain
                knowledge. Deploying a Generative AI system is just a piece of a
                puzzle. It requires end-to-end monitoring of system performance
                and user experience. Workativ provides a no-code platform for
                Generative AI implementation. Our post-implementation support is
                superior to helping clients onboard and efficiently manage all
                processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                Are you interested in successfully implementing your Generative
                AI workflows?{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  Book a demo today
                </a>{" "}
                with Workativ.
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/top-itsm-automation-strategies"
              className="font-section-normal-text-testimonials"
            >
              Top ITSM Automation Strategies That Are Redefining IT Support
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/top-itsm-trends-2023"
              className="font-section-normal-text-testimonials"
            >
              TOP ITSM Trends 2023: Preparing for the Future
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              ITSM Chatbot: Use Cases and Benefits Explained
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
